const initialState = {
    hideLogoMenu: false,
    bgColor: "#eceae7",
    showArrowTop: false,
    changeModal: {
        show: false,
        data: {
            bg: "foz_de_prata/map/bg_popup_ethula.png",
            bgAlt: "Paisagem Ethula",
            logo: "foz_de_prata/map/logo_ethula.svg",
            logoAlt: "Logo Ethula",
            btnLink: "https://ethula.fozdeprata.pt",
            extraClass: "ethula",
            features: [
                {
                    "logo": "svgs/symbols_features/building.inline.svg",
                    "logoAlt": "Apartamentos",
                    "text": "46 Apartamentos"
                },
                {
                    "logo": "svgs/symbols_features/ria.inline.svg",
                    "logoAlt": "Vista Ria",
                    "text": "Vista Ria"
                },
                {
                    "logo": "svgs/symbols_features/park.inline.svg",
                    "logoAlt": "Estacionamento Privado",
                    "text": "Estacionamento Privado"
                },
                {
                    "logo": "svgs/symbols_features/typology.inline.svg",
                    "logoAlt": "Tipologia",
                    "text": "T1-T2"
                },
                {
                    "logo": "svgs/symbols_features/terrace.inline.svg",
                    "logoAlt": "Terraços",
                    "text": "Terraços Ajardinados"
                },
                {
                    "logo": "svgs/symbols_features/park_bike.inline.svg",
                    "logoAlt": "Estacionamento de Bicicletas",
                    "text": "Estacionamento de Bicicletas"
                },
                {
                    "logo": "svgs/symbols_features/kitchen.inline.svg",
                    "logoAlt": "Cozinha Equipada",
                    "text": "Cozinhas Equipadas"
                },
                {
                    "logo": "svgs/symbols_features/solar_panels.inline.svg",
                    "logoAlt": "Painéis Fotovoltaicos",
                    "text": "Painéis Fotovoltaicos"
                }
            ]
        }
    }
}

const TOGGLE_HIDELOGOMENU = 'TOGGLE_HIDELOGOMENU'
const TOGGLE_BGCOLOR = 'TOGGLE_BGCOLOR'
const TOGGLE_SHOWARROWTOP = 'TOGGLE_SHOWARROWTOP'
const CHANGE_MODAL = 'CHANGE_MODAL'


export const toggleHideLogoMenu = hideLogoMenu => ({
    type: TOGGLE_HIDELOGOMENU, hideLogoMenu
})
export const toggleBgColor = bgColor => ({
    type: TOGGLE_BGCOLOR, bgColor
})
export const toggleShowArrowTop = showArrowTop => ({
    type: TOGGLE_SHOWARROWTOP, showArrowTop
})
export const changeModal = modal => ({
    type: CHANGE_MODAL, modal
})


const App = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_HIDELOGOMENU:
            return { ...state, hideLogoMenu: action.hideLogoMenu }
        case TOGGLE_BGCOLOR:
            return { ...state, bgColor: action.bgColor }
        case TOGGLE_SHOWARROWTOP:
            return { ...state, showArrowTop: action.showArrowTop }
        case CHANGE_MODAL:
            return { ...state, modal: action.modal }
        default:
            return state
    }
}
export default App